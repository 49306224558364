<script lang="ts" setup>
defineProps<{
  contentClass?: string
}>()

const isOpen = defineModel({
  default: false
})
</script>

<template>
  <DialogRoot v-model:open="isOpen">
    <slot name="trigger" />

    <DialogPortal>
      <DialogOverlay
        class="radix-dialog-overlay data-[state=open]:animate-in data-[state=open]:fade-in data-[state=closed]:animate-out data-[state=closed]:fade-out"
      />

      <DialogContent
        class="radix-dialog-content grid gap-4 p-6 w-90vw max-w-450px data-[state=open]:animate-in data-[state=open]:fade-in data-[state=open]:zoom-in-90 data-[state=open]:slide-in-t-2/4 data-[state=open]:slide-in-l-2/4 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:zoom-out-90 data-[state=closed]:slide-out-t-2/4 data-[state=closed]:slide-out-l-2/4"
        :class="contentClass"
      >
        <slot />
      </DialogContent>
    </DialogPortal>
  </DialogRoot>
</template>
